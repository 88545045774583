<template>
  <div>
    <reservation/>
  </div>

</template>

<script>
import Reservation from '@/views/ReservationPage/ReservationFragment.vue'

export default {
  name: 'ReservationPage',
  components: { Reservation }
}
</script>

<style scoped>

</style>
