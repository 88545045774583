import { render, staticRenderFns } from "./NoContentDisplay.vue?vue&type=template&id=263b9590&scoped=true&"
import script from "./NoContentDisplay.vue?vue&type=script&lang=js&"
export * from "./NoContentDisplay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "263b9590",
  null
  
)

export default component.exports